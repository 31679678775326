<template>
  <div>
    <img src="../../../assets/images/login/mail.png" width="50"  alt="">
    <h1 class="mt-3 mb-0">Success !</h1>
    <p>A email has been send to {{email}}. Please check for an email from company and click on the included link to reset your password.</p>
    <div class="d-inline-block w-100">
      <router-link class="btn btn-bluish mt-3" to='/home'>Back to Home</router-link>
      <!-- <button type="submit" class="btn btn-bluish mt-3">Back to Home</button> -->
    </div>
  </div>
</template>
<script>
export default {
  name: 'ConfirmMail1',
  data(){
    return{
      email:''
    }
  },
   mounted() {
    this.email = this.$route.params.email;
  },
  
}
</script>
